import React, { useEffect } from "react";
import { Button, Input, RadioGroup, Textarea } from "react-rainbow-components";
import APIHandler from "../../handler/APIHandler";


import {
    GoogleMap,
    InfoWindow,
    Marker,
    useJsApiLoader,
    Circle
} from "@react-google-maps/api";

import './d2d.scss';
import Skeleton from "react-loading-skeleton";

const D2D = () => {
    const containerStyle = {
        width: "100%",
        height: "100vh",
    };

    const historyData = [
        {
            id: 1,
            user: "Sarah Miller",
            action: "Updated status to active",
            timestamp: "2024-01-22 14:30",
            status: "active",
        },
    ]


    const getStateLabel = (status) => {
        switch (status) {
            case "NOT_OPENED":
                return "Nicht geöffnet";
            case "OPENED_BAD_TIMING":
                return "Geöffnet - Schlechter Zeitpunkt";
            case "OPENED_DONT_COME_BACK":
                return "Geöffnet - Nie wieder kommen";
            case "LEAD":
                return "Lead eingesammelt";
            case "EVENT_BOOKED":
                return "Termin gebucht";
        }

    }

    const status_options = [
        { value: "NOT_OPENED", label: getStateLabel("NOT_OPENED") },
        { value: "OPENED_BAD_TIMING", label: getStateLabel("OPENED_BAD_TIMING") },
        { value: "OPENED_DONT_COME_BACK", label: getStateLabel("OPENED_DONT_COME_BACK") },
        { value: "LEAD", label: getStateLabel("LEAD") },
        { value: "EVENT_BOOKED", label: getStateLabel("EVENT_BOOKED") }
    ];



    const { isLoaded } = useJsApiLoader({
        id: "google-map-script",
        googleMapsApiKey: "AIzaSyCUjPiR-uJ7soEgWCm4IphXDVCXURXhQP8",
    });


    const [fetchingData, setFetchingData] = React.useState(false);


    const [centered, setCentered] = React.useState(false);
    const [map, setMap] = React.useState(null);
    const [userLocation, setUserLocation] = React.useState(null);
    const [status, setStatus] = React.useState(null)
    const [notes, setNotes] = React.useState("")


    const [storedAddresses, setStoredAddresses] = React.useState([]);

    //Adress lookup
    const [selectedLocation, setSelectedLocation] = React.useState(null);
    const [address, setAddress] = React.useState("");

    const [fetchedAddress, setFetchedAddress] = React.useState(null);

    const handleMapClick = async (event) => {
        const lat = event.latLng.lat();
        const lng = event.latLng.lng();
        setSelectedLocation({ lat, lng });
        await fetchAddress(lat, lng);
    };

    const fetchAddress = async (lat, lng) => {
        setFetchingData(true);
        const geocodingApi = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyCUjPiR-uJ7soEgWCm4IphXDVCXURXhQP8`;
        try {
            const response = await fetch(geocodingApi);
            const data = await response.json();
            if (data.status === "OK" && data.results[0]) {
                setAddress(data.results[0].formatted_address);
                console.log(data.results[0]);

                setSelectedLocation(data.results[0].geometry.location);
                console.log("Address found:", data.results[0].formatted_address);

            } else {
                setAddress("Address not found.");
            }
        } catch (error) {
            console.error("Error fetching address:", error);
            setAddress("Error retrieving address.");
        }
    };

    const saveAddressHistory = async (data) => {
        console.log(data);


        setNotes("");
        setStatus(null);

        data.langitude = selectedLocation.lat;
        data.longitude = selectedLocation.lng;
        data.timestamp = new Date().toISOString();
        data.mitarbeiter_id = 1;


        let response = await APIHandler.D2D.addAddressHistory(data);
        fetchAddressData(address);
        fetchAllAddresses();
        console.log(response);
    }

    useEffect(() => {
        if (navigator.geolocation && map) {
            // Get the initial position and center the map
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const pos = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    };
                    setUserLocation(pos);
                    map.setCenter(pos);
                },
                (error) => {
                    console.error("Error fetching initial location: ", error);
                    setUserLocation({
                        lat: 51.501062,
                        lng: 7.570688,
                    });
                },
                { enableHighAccuracy: true }
            );

            // Now, update the user marker position without recentering the map
            const watchId = navigator.geolocation.watchPosition(
                (position) => {
                    const pos = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    };
                    setUserLocation(pos);
                },
                (error) => {
                    console.error("Error watching position: ", error);
                },
                { enableHighAccuracy: true, maximumAge: 0 }
            );

            return () => {
                navigator.geolocation.clearWatch(watchId);
            };
        }
    }, [map]);



    const fetchAllAddresses = async () => {
        let response = await APIHandler.D2D.getAddressData("");
        console.log(response);

        setStoredAddresses(response.addresses);

        console.log("Stored addresses", storedAddresses);

    }

    useEffect(() => {
        fetchAllAddresses();
    }, [map]);




    const onLoad = React.useCallback(function callback(map) {
        setMap(map);
    });


    const fetchAddressData = async (address) => {
        setFetchingData(true);
        let response = await APIHandler.D2D.getAddressData(address);
        console.log(response);
        setFetchingData(false);
        setFetchedAddress(response.address);

    }

    useEffect(() => {
        let user = localStorage.getItem("user");
        if (user == null) {
            window.location.href = "/login";
        }


        //remove footer element from the page
        let footer = document.querySelector("footer");
        if (footer) {
            footer.style.display = "none";
        }


        let header = document.querySelector("header");
        if (header) {
            header.style.display = "none";
        }


    }, []);

    useEffect(() => {
        fetchAddressData(address);
    }, [address]);



    const getCircleOptions = (status) => {
        switch (status) {
            case "NOT_OPENED":
                return {
                    fillColor: "#68696a",
                    fillOpacity: 0.6,
                    strokeColor: "#3d3d3e",
                    strokeOpacity: 0.7,
                    strokeWeight: 2,
                    clickable: false,
                }
            case "OPENED_BAD_TIMING":
                return {
                    fillColor: "#ffd500",
                    fillOpacity: 0.2,
                    strokeColor: "#b89900",
                    strokeOpacity: 0.7,
                    strokeWeight: 2,
                    clickable: false,
                }
            case "OPENED_DONT_COME_BACK":

                return {
                    fillColor: "#FF0000",
                    fillOpacity: 0.2,
                    strokeColor: "#FF0000",
                    strokeOpacity: 0.7,
                    strokeWeight: 2,
                    clickable: false,
                }
            case "LEAD":
                return {
                    fillColor: "#00FF00",
                    fillOpacity: 0.2,
                    strokeColor: "#00FF00",
                    strokeOpacity: 0.7,
                    strokeWeight: 2,
                    clickable: false,
                }
            case "EVENT_BOOKED":
                return {
                    fillColor: "#00a300",
                    fillOpacity: 0.3,
                    strokeColor: "#004d00",
                    strokeOpacity: 0.7,
                    strokeWeight: 2,
                    clickable: false,
                }
        }
    }



    return (


        isLoaded ? (
            <GoogleMap
                mapContainerStyle={containerStyle}
                zoom={19}
                onLoad={onLoad}
                onClick={handleMapClick}
                options={{
                    gestureHandling: "greedy",
                    mapTypeId: "hybrid",
                }}
            >
                {userLocation && (
                    <>
                        <Marker
                            position={userLocation}
                            icon={{
                                url: "https://maps.google.com/mapfiles/kml/shapes/man.png", // Custom marker icon for the user
                                scaledSize: new window.google.maps.Size(40, 40), // Adjust the icon size
                            }}
                        />
                        <Circle
                            center={userLocation}
                            radius={100}
                            options={{
                                fillColor: "#00AAFF",
                                fillOpacity: 0.2,
                                strokeColor: "#005BB5",
                                strokeOpacity: 0.7,
                                strokeWeight: 2,
                                clickable: false,
                            }}
                        />
                    </>
                )}


                {storedAddresses.map((address) => (
                    <Circle
                        center={{ lat: parseFloat(address.latitude), lng: parseFloat(address.longitude) }}
                        radius={10}
                        options={getCircleOptions(address.status)}
                    />
                ))}


                {address && selectedLocation && (
                    <InfoWindow
                        position={selectedLocation} // Display InfoWindow at clicked position
                        onCloseClick={() => setSelectedLocation(null)} // Close the popup
                    >
                        <div className="popup">
                            <div className="popup__content">

                                <div className="popup__section">
                                    <div className="popup__section-title">
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
                                            <path d="M13.5 6.5L8 2.5L2.5 6.5V13.5H13.5V6.5Z" stroke="currentColor" />
                                            <path d="M6 13.5V9.5H10V13.5" stroke="currentColor" />
                                        </svg>
                                        Addresse
                                    </div>
                                    <div className="popup__address">{address}</div>
                                </div>

                                <div className="popup__section">
                                    <div className="popup__status">

                                        {fetchingData ? <Skeleton count={1} /> : (
                                            <>
                                                <RadioGroup
                                                    id="status"
                                                    options={status_options}
                                                    value={status}
                                                    onChange={(event) => setStatus(event.target.value)}
                                                />

                                                <Textarea
                                                    id="notes"
                                                    rows={4}
                                                    value={notes}
                                                    onChange={(event) => setNotes(event.target.value)}
                                                    placeholder="Notiz hinzufügen"
                                                    style={{ marginTop: "1rem" }}
                                                    className="rainbow-m-vertical_x-large rainbow-p-horizontal_medium rainbow-m_auto"
                                                />

                                                <Button
                                                    shaded
                                                    label="Speichern"
                                                    onClick={() =>
                                                        saveAddressHistory({
                                                            address: address,
                                                            status: status,
                                                            note: notes
                                                        })
                                                    }
                                                    style={{ marginTop: "1rem", width: "100%" }}
                                                    variant="brand"
                                                    className="rainbow-m-around_medium"
                                                />
                                            </>
                                        )}
                                    </div>
                                </div>

                                <div className="popup__section">
                                    <div className="popup__history-list">

                                        {fetchingData && <Skeleton count={1} />}

                                        {!fetchingData && fetchedAddress?.history?.sort((a, b) => { return new Date(a.timestamp) < new Date(b.timestamp) ? 1 : -1 }).map((entry) => (
                                            <div key={entry.id} className="popup__history-item">
                                                <div className="popup__history-user" style={{ '--user-color': entry.mitarbeiter.system_farbe }}>
                                                    <span>{entry.mitarbeiter.kuerzel}</span>
                                                </div>
                                                <div style={{ width: "100%" }}>
                                                    <div className="popup__history-item-header">
                                                        <span className="popup__history-item-user">{getStateLabel(entry.new_status)}</span>
                                                        <span className="popup__history-item-time">{new Date(entry.timestamp).toLocaleString().slice(0, -3)}</span>
                                                    </div>
                                                    <div className="popup__history-item-action">
                                                        {entry.note && `${entry.note}`}
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </InfoWindow>
                )}
            </GoogleMap>

        )
            : (<p>Map is loading...</p>)
    );
};
export default D2D;
